body {
  background-image: url("../../images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  --primary: #FBFBF2;
}

.app {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--primary);
  backdrop-filter: blur(5px);
	background-color: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.navbar .name-container {
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  max-width: 100px;
  width: 30%;
  font-family: 'Open Sans', sans-serif;
  margin: 1% 3%;
}

.name-container .first {
  display: flex;
  justify-content: flex-start;
}

.name-container .last {
  display: flex;
  justify-content: flex-end;
}

.navbar .nav-links {
  max-width: 200px;
  width: 55%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  
}

 a {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 10px;
}

a:link {
  text-decoration: none;
  color: var(--primary);
}

a:visited {
  text-decoration: none;
  color: var(--primary);
}

a:hover {
  border: 1px solid var(--primary);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FBFBF211;
}

.page-content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  font-family: 'Roboto Slab', serif;
}

.page-content-header {
  font-size: 1.25rem;
  margin: 0 0 0 8px;
}

.divider-container {
  margin: 8px 0;
  padding: 0 8px;
  border-top: 1px solid var(--primary);
}

.divider-container .title {
  font-size: 1.75rem;
  white-space: nowrap;
}

.resume-btn {
  padding: 10px 16px;
  border: 1px solid var(--primary);
  border-radius: 8px;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
  background: none;
  color: var(--primary);
  cursor: pointer;
}

.portfolio-btn {
	padding: 8px 42px;
	border: 1px solid var(--primary);
	border-radius: 8px;
	font-size: 1.1rem;
	font-family: 'Open Sans', sans-serif;
	background: none;
	color: var(--primary);
	cursor: pointer;
}

.resume-btn:hover {
	background-color: #FBFBF211;
}

.footer {
  position: fixed;
  bottom: 6%;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Open Sans', sans-serif;
}

.portfolio-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid var(--primary);
  border-radius: 8px;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
  background: none;
  color: var(--primary);
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 8px;
}
